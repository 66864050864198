export const pageData = {
  title: '2018 Top 10 Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '2018 Top 10 Real Estate Websites',
      url: '/top-10-real-estate-websites/2018',
    },
  ],
  uri: '/top-10-real-estate-websites/2018',
}

export const websiteTexts = [
  {
    title: 'Egypt \nSherrod',
    subtitle: 'Atlanta, GA',
    description:
      'When it comes to real estate industry superstars, few shine as bright as Egypt Sherrod. Hers is a stand-out brand built on years of experience and polished expertise in home-flipping and interior design, plus a personality that just naturally connects with people. On EgyptSherrodRealEstate.com, Agent Image captures her signature flair, packaging it with the substance and functionality that the Keller Williams website design is known for.',
  },
  {
    title: 'The Altman \nBrothers',
    subtitle: 'Beverly Hills, CA',
    description:
      'Serving the exclusive, high-end Los Angeles real estate market, but known worldwide as the stars of Bravo’s Million Dollar Listing, Josh and Matt Altman truly are the golden boys of real estate. Their website’s fluid navigation, intuitive layout, and property-centric visuals create a browsing experience that’s as sophisticated as the homes the brothers represent. More than just a real estate resource, the website also connects clients to the Brothers’ training and speaking services.',
  },
  {
    title: 'The Nartey \nGroup',
    subtitle: 'Beverly Hills, CA',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
  {
    title: 'The Habibi \nGroup',
    subtitle: 'San Francisco, CA',
    description:
      'As a globally renowned real estate expert, Roh Habibi represents one of the world’s most sought-after markets with an amazing real estate website. A website worthy of San Francisco’s unmistakable prestige, TheHabibiGroup.com welcomes visitors with videos highlighting both stunning city views and Roh doing what he does best. The stunning visuals are paired with outstanding content and intuitive navigation, lending to a productive user experience overall.',
  },
  {
    title: 'Sheerin Feizi \nGroup',
    subtitle: 'Boynton Beach, FL',
    description:
      'From edge-to-edge community photos that welcome every visitor to the site, to the smooth transition animation effects at every scroll, sfeizigroup.com is a visual delight. Its clean color scheme is contrasted with vibrant images that fully capture the spirit of the southern Florida communities of Delray Beach, Boca Raton, and Boynton Beach. The information featured in the site pages are tailored to real estate audiences of all kinds – from buyers, sellers, to commercial property investors.',
  },
  {
    title: '901 Real Estate Services',
    subtitle: 'Memphis, TN',
    description:
      'No frills, all thrills. Straightforward is the name of the game when you log on to 901RES.com, an exceptional real estate broker website serving the Memphis, TN market. Whether you’re property owner looking for top dollar for your rental or home for sale, or a buyer searching for the perfect home, this website is your gateway to expert services made accessible through smart design.',
  },
  {
    title: 'Melanie \nMcLaird',
    subtitle: 'Tulsa, OK',
    description:
      'Exceptional South Tulsa real estate opportunities seem to jump right out of your screens on the sleek and modern MelanieSellsTulsa.com. This real estate agent website uses the minimalist aesthetic to great effect, creating an intuitive and easy-to-navigate user experience. This deliberate design choice also makes for an excellent mobile real estate website experience for customers who are always on the go.',
  },
  {
    title: 'Avi Dan-Goor',
    subtitle: 'Las Vegas, NV',
    description:
      'The best luxury real estate websites are those that wow you as soon as the first screen loads. AviDanGoor.com does this to great effect, as its single-screen homepage leaves it to crisp photography and clear calls-to-action to get the job done. All you need is right in front of you, as four main buttons lead to Avi’s profile, search functions, and contact page.',
  },
  {
    title: 'Jimmy \nMartinez',
    subtitle: 'Beverly Hills, CA',
    description:
      'Los Angeles may be one of the best known cities around the world, but through HousesInLA.com, it appears with a fresh look and feel. Captivating full-page photos fill a deliberate minimalist design on the main screen. Plenty of off-center focal points are used here, lending a dynamic and modern feel to the page. Inside, punchy colors in the location and property photos complement well-written and easy-to-read text content.',
  },
  {
    title: 'Urban Key \nRealty',
    subtitle: 'Miami, FL',
    description:
      'With over $1 billion in total sales, $300 million in active listings, and the hit Netflix show “Selling Sunset” under its belt, The Oppenheim Group is undoubtedly at the top of its game. And that’s immediately obvious with their website, which boasts a sleek theme, intuitive navigation, dynamic animations, and interactive features — exactly what you’d expect from one of L.A.’s most revered real estate groups.',
  },
]
