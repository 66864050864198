import React from 'react'

//meta data
import {
  pageData as page,
  websiteTexts,
} from '../../../data/Top10RealEstateWebsites/2018'
import meta from '../../../hooks/Top10RealEstateWebsites/2018'

//style
import * as cx from './Top10RealEstateWebsites.module.scss'
import Top10RealEstateWebsite from '../../../components/pages/top-10-real-estate-websites'

const Top10RealEstateWebsite2018 = () => {
  const {
    BannerBG,
    BannerImage1,
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
  } = meta()

  const items = {
    item1: Item1,
    item2: Item2,
    item3: Item3,
    item4: Item4,
    item5: Item5,
    item6: Item6,
    item7: Item7,
    item8: Item8,
    item9: Item9,
    item10: Item10,
  }

  const bannerDetails = {
    heading: '2018 Top 10\nReal Estate Websites',
    subHeading: 'Be Inspired by These Trend-Setting Realtor Websites!',
    styles: {
      imageStyle: cx.bannerImage1,
      textHeadingStyle: cx.bannerTextHeading,
      textSubheadingStyle: cx.bannerTextSubheading,
      textContainerStyle: 'sm-max:!pb-[29.4375rem]',
    },
    image: BannerImage1,
  }

  return (
    <Top10RealEstateWebsite
      pageDetails={{
        pageTitle: page.title,
        breadcrumbs: page.breadcrumbs,
        pageUri: page.pageUri,
      }}
      banner={{
        bg: BannerBG,
        heading: bannerDetails.heading,
        subHeading: bannerDetails.subHeading,
        styles: bannerDetails.styles,
        image: bannerDetails.image,
      }}
      websites={{ type: 'B', images: items, texts: websiteTexts }}
    />
  )
}

export default Top10RealEstateWebsite2018
